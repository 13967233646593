import * as React from 'react';
import LayoutFrame from '../components/layout/LayoutFrame';


const PrimaryLayout = ({ children }) => {
  return (
    <LayoutFrame>
      {children}
    </LayoutFrame>
  );
}

export default PrimaryLayout;