import * as React from "react";
import {useState} from "react";
import Nav from '../Navigation/nav';
import {Link} from "gatsby";
import "./header.scss";
import largeLogo from "../../images/curtie-logo.svg";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

const styles = {
  root: {
    color: '#000',
    border: '1px solid',
    'margin-left': 'auto',
    '&:focus, &:active': {
      'border-width': '2px'
    }
  },
};

const Header = ({classes}) => {
  const [open, toggleMenu] = useState(false)

  const handleToggle = () => {
      toggleMenu(!open)
  }

  return (
    <header className="header">
      <div className="container">
        <div className="logo-wrapper">
          <Link to="/" className="logo-link"><img className="logo" src={largeLogo} alt="Curtie Dot Com, Inc."/></Link>
          <Hidden mdUp>
            <IconButton className={clsx(classes.root)} onClick={handleToggle}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </div>
        <Nav open={open} setOpen={toggleMenu}/>
      </div>
    </header>
  );
}

export default withStyles(styles)(Header);
