
import * as React from 'react';

const PageContent = ({ children}) => {

  return (
    <main>
      {children}
    </main>
  );
};

export default PageContent;